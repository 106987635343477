const startDate = new Date().setDate(1);
const filterConfig = {
  type: "object",
  properties: {
    // box_day_id: {
    //     type: 'integer',
    // },
    // report_id: {
    //     type: 'integer',
    // },
    start_date: {
      type: "string",
      format: "date",
    },
    end_date: {
      type: "string",
      format: "date",
    },
    box_type_id: {
      title: "Tipo",
      type: "number",
    },    
    search: {
      type: "string",
    },
  },
};
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
};
export default getFiltersConfig;
