const startDate = new Date().setDate(1);
const filterConfig = {
  type: "object",
  properties: {
    // movement_id: {
    //     type: 'integer',
    // },
    // report_id: {
    //     type: 'integer',
    // },
    start_date: {
      type: "string",
      format: "date",
      // Default debe ser el principio de este mes
      // default: new Date(startDate).toISOString().slice(0, 10),
    },
    end_date: {
      type: "string",
      format: "date",
      // Default debe ser el final de este mes
      // default: new Date().toISOString().slice(0, 10),
    },
    type: {
      type: "number",
    },
    origin_type: {
      type: "number",
    },
    search: {
      type: "string",
    },
  },
};
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
};
export default getFiltersConfig;
