import { useContext, useState } from "react";
import getConfig from "../configs/tableConfig";
import getReportConfig from "../configs/tableReportConfig";
import BeelditTableHeaderActions from "@beeldit/core/components/BeelditTableHeaderActions";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useRemoveAction from "../actions/remove/useRemoveAction";
import useCreateAction from "../actions/create/useCreateAction";
import useEditAction from "../actions/edit/useEditAction";
import useListAction from "../actions/list/useListAction";
import { Card, Tab, Tabs } from "react-bootstrap";
import useImportAction from "../actions/import/useImportAction";
import useExportAction from "../actions/export/useExportAction";
import { report } from "process";
import useReportAction from "../actions/report/useReportAction";
import useExportReportAction from "../actions/export-report/useExportReportAction";
import {TenantAndCommerceContext} from "@main/contexts/TenantAndCommerceContext";

function BoxDaysPage(props: any) {
  const { filters, formDefaultValues } = props;

  const { user } = useContext(AuthContext);

  const { commerce } = useContext(TenantAndCommerceContext);

  const [boxDaysTabLoaded, setBoxDaysTabLoaded] = useState(false);

  const {
    indexFunction,
    actionTemplate: listActionTemplate,
    setTableConfig,
  } = useListAction(user);

  const {
    actionTemplate: reportActionTemplate,
  } = useReportAction(user);

  const { initializeFunction, actionTemplate: createActionTemplate } =
    useCreateAction(indexFunction);

  const { getFunction, actionTemplate: editActionTemplate } =
    useEditAction(indexFunction);

  const { prepareRemoveFunction, actionTemplate: removeActionTemplate } =
    useRemoveAction(indexFunction);
  const { importFunction, actionTemplate: importActionTemplate } =
    useImportAction(indexFunction, formDefaultValues);


  const { exportFunction: exportReportFunction } =
    useExportReportAction(filters);

  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({
    initializeFunction, // We pass the function to initialize a new element (used in the create button)
    indexFunction, // We pass the function to index the table
    getFunction, // We pass the function to get an element (used in the edit button)
    prepareRemoveFunction,
    importFunction,
    commerce
    /**newActionsTableConfig**/
  });
  setTableConfig(tableConfig);

  let reportTableConfig = getReportConfig({
    exportReportFunction,
    /**newActionsTableConfig**/
  });
  // setReportTableConfig(reportTableConfig);


  const onSelectTab = (key: string) => {
    if (key === "box_days") {
      setBoxDaysTabLoaded(true);
    }
  };

  return (
    <Tabs
      defaultActiveKey="report"
      id="uncontrolled-tab-example"
      className=""
      onSelect={(key: any) => {
        onSelectTab(key);
      }}
    >
      <Tab eventKey="report" title={t("report")}>
        <Card>
          <Card.Header className="d-flex align-items-center justify-content-between ps-3 text-bg-primary">
            <span>{t("report")}</span>
            <div>
              <BeelditTableHeaderActions
                  actions={reportTableConfig.actions}
              ></BeelditTableHeaderActions>
            </div>
          </Card.Header>

          <Card.Body>
            {reportActionTemplate}
            {/* newTemplateActions */}
          </Card.Body>
        </Card>
      </Tab>
      <Tab eventKey="box_days" title={t("box_days")}>
        {boxDaysTabLoaded && (
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between ps-3 text-bg-primary">
              <span>{t("box_days")}</span>
              <div>
                <BeelditTableHeaderActions
                  actions={tableConfig.actions}
                ></BeelditTableHeaderActions>
              </div>
            </Card.Header>

            <Card.Body>
              {listActionTemplate}
              {createActionTemplate}
              {editActionTemplate}
              {removeActionTemplate}
              {importActionTemplate}
              {/* newTemplateActions */}
            </Card.Body>
          </Card>
        )}
      </Tab>
    </Tabs>
  );
}

export default BoxDaysPage;
