import useExportReport from "./useExportReport";

function useExportReportAction(filters: any = {}): any {
  const toExport = useExportReport();
  const exportFunction = () => toExport();

  return {
    exportFunction,
  };
}

export default useExportReportAction;
