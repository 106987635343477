import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { Download, Pencil, Plus, Trash } from "@phosphor-icons/react";
import { UploadSimple } from "@phosphor-icons/react/dist/ssr";

const tableConfig: FinalTableConfig = {
  index: null,
  actions: [
    {
      name: TableAction.EXPORT,
      label: "export",
      showLabel: true,
      allowed: true,
      show: true,
      action: null,
      icon: <UploadSimple size={20} />,
    },
    {
      name: TableAction.IMPORT,
      label: "import",
      showLabel: true,
      allowed: true,
      show: true,
      action: null,
      icon: <Download size={20} />,
    },
    {
      name: TableAction.CREATE,
      label: "create",
      showLabel: true,
      allowed: true,
      show: true,
      action: null,
      icon: <Plus size={20} />,
    },
  ],
  rowActions: [
    {
      name: RowAction.EDIT,
      label: "edit",
      icon: <Pencil size={20} />,
      allowed: true,
      show: true,
      action: (element: any) => {
        console.log("edit", element);
      },
    },
    {
      name: RowAction.DELETE,
      label: "delete",
      icon: <Trash size={20} />,
      allowed: true,
      show: true,
      action: (element: any) => {
        console.log("delete", element);
      },
    },
  ],
  rowConfiguration: {
    class: (element: any) => {
      return null;
    },
  },
  columns: [
    {
      name: "id",
      label: "#",
      key: "id",
      type: "number",
      show: true,
      allowOrder: true,
    },
    {
      name: "concept",
      label: "concept",
      key: "concept",
      type: "string",
      show: true,
      allowOrder: true,
    },
    {
      name: "date",
      label: "date",
      key: "date",
      type: "date",
      show: true,
      allowOrder: true,
    },
    {
      name: "type",
      label: "type",
      key: "type",
      type: "translation",
      translation_prefix: "movement_type",
      show: true,
      allowOrder: true,
    },
    {
      name: "amount",
      label: "amount",
      key: "amount",
      type: "currency",
      currency: "EUR",
      show: true,
      allowOrder: true,
    },
  ],
  pagination: {
    enabled: true,
    back: true,
  },
};
const getConfig: any = (functions: any) => {
  const {
    getFunction,
    indexFunction,
    initializeFunction,
    prepareRemoveFunction,
    importFunction,
    exportFunction,
    commerce
  } = functions;
  tableConfig.actions[0].action = () => exportFunction();
  tableConfig.actions[1].action = () => importFunction();
  tableConfig.actions[2].action = () => initializeFunction();
  tableConfig.index = () => indexFunction();
  tableConfig.rowActions[0].action = (id: any) => getFunction(id);
  tableConfig.rowActions[1].action = (id: any) => prepareRemoveFunction(id);
  tableConfig.columns[4].currency = commerce ? commerce.currency : 'EUR';

  return tableConfig;
};
export default getConfig;
