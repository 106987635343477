import { AuthContext } from '@beeldit/user-and-access/auth/AuthContext';
import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import CommerceSelector from "@main/commerces/components/CommerceSelector";


interface MenuItem {
  label: string;
  to: string;
  children?: MenuItem[];
}

function AppNavbar(props: any) {
  const { user } = useContext(AuthContext);

  const {items} = props

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate('/login');
  };
  return (
    <Navbar collapseOnSelect expand="lg" fixed="top">
        {/* <Navbar.Brand href="#home">
            <img src="/logo.svg" alt="BeeldIt" width="150"/>
        </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {items.map((item: MenuItem) => (
              <Nav.Link className="d-block d-md-none" as={Link} to={item.to}>{item.label}</Nav.Link>
            ))}
          </Nav>
          <Nav>
          <CommerceSelector options={{}} />
          <NavDropdown title={`Hola ${user?.name ?? ''}!`} id="collasible-nav-dropdown">
            <NavDropdown.Item href="#profile">Perfil</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#logout" onClick={handleLogout}>Cerrar sesión</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}

export default AppNavbar;

