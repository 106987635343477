import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { UploadSimple } from "@phosphor-icons/react/dist/ssr";

const tableConfig: FinalTableConfig = {
  index: null,
  actions: [
    {
      name: TableAction.EXPORT,
      label: "export",
      showLabel: true,
      allowed: true,
      show: true,
      action: null,
      icon: <UploadSimple size={20} />,
    },
  ],
  rowActions: [],
  rowConfiguration: {
    class: (element: any) => {
      return null;
    },
  },
  columns: [],
  pagination: {
    enabled: true,
    back: true,
  },
};
const getReportConfig: any = (functions: any) => {
  const {
    exportReportFunction,
  } = functions;
  tableConfig.actions[0].action = () => exportReportFunction();
  return tableConfig;
};
export default getReportConfig;
