import { useContext, useEffect } from "react";
import useIndex from "./useIndex";
import AddQueryParamsToURL from "@beeldit/core/helpers/AddQueryParamsToURL";
import { ReportActionContext } from "./ReportActionContext";
import useFilters from "@beeldit/core/helpers/useFilters";
import BeelditTableFilters from "@beeldit/core/components/BeelditTableFilters";
import getFilterUIConfig from "../../configs/filterUIConfig";
import getFiltersConfig from "../../configs/filterConfig";
import BoxDayReport from "../../components/BoxDayReport";

function useReportAction(user: any): any {
  const addQueryParamsToURL: any = AddQueryParamsToURL();

  /** Crud functions */
  const index = useIndex();
  const indexFunction = () => index();

  /** Filter loading */
  const { tableFilters, setTableFilters } = useContext(ReportActionContext);

  useEffect(() => {
    index();
  }, [tableFilters]);

  /**<newActionsFunctions>**/

  /** Table Filters configuration */
  const filterUIConfig = getFilterUIConfig(user);
  const filterConfig = {
    schema: getFiltersConfig(user),
    uiSchema: filterUIConfig.uiScheme,
    widgets: filterUIConfig.widgets,
    element: tableFilters,
    onChange: (form: any) => {
      setTableFilters(form.formData);
      addQueryParamsToURL(form.formData);
    },
  };

  let actionTemplate = (
    <>
      <BeelditTableFilters formConfig={filterConfig} />
      <BoxDayReport></BoxDayReport>
    </>
  );
  /** END EDIT TABLE ACTION */

  return {
    indexFunction,
    actionTemplate,
  };
}

export default useReportAction;
