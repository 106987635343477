import React, { useState, useEffect } from "react";
import Select, { ActionMeta, SingleValue } from "react-select";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import index from "@beeldit/core/services";

interface Option {
  label: string;
  value: number | string;
}

function CustomSelectWidget(props: any) {
  const { tenantId, commerceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  console.log(commerceId)

  const { id, value, required, disabled, readonly, onChange } = props;
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [options, setOptions] = useState<Option[]>([]);
  const [elementsData, setElementsData] = useState({ elements: [] });

  const errorHandler = useErrorHandler();

  useEffect(() => {
    index("commerces", "relations=")
      .then((response: any) => {
        setElementsData(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, null);
      });
  }, []);
  useEffect(() => {
    const options = elementsData.elements.map((commerce: any) => {
      return { label: commerce.name, value: commerce.id };
    });
    setOptions(options);
    if (commerceId) {
      const currentSelectedOption = options.find(
          (option) => option.value == commerceId
      );
      setSelectedOption(currentSelectedOption || null);
    } else {
      const currentSelectedOption = options.find(
          (option) => option.value === value
      );
      setSelectedOption(currentSelectedOption || null);
    }
  }, [elementsData, value, commerceId]);

  const handleOnChange = (
    newValue: SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    const selectedOption = newValue as Option | null;
    setSelectedOption(selectedOption);
    const model = selectedOption?.value ?? null;
    if (onChange) {
      onChange(model);
    }
    // const newPath = location.pathname.replace(`/${commerceId}/`, `/${selectedOption?.value}/`);

    const pathSegments = location.pathname.split('/');
    // Divide la ruta en segmentos y reemplaza el segundo parámetro
    console.log('length', pathSegments.length)
    if (pathSegments.length >= 5 && selectedOption && selectedOption.value) {
      pathSegments[3] = selectedOption.value.toString(); // Modifica el segundo parámetro en la ruta
      const newPath = pathSegments.join('/');


      navigate(newPath);
      window.location.href = newPath;
    }
  };

  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "lightgray",
      borderRadius: 0,
    }),
  };

  return (
    <Select
      id={id}
      options={options}
      value={selectedOption}
      required={required}
      isDisabled={disabled || readonly}
      onChange={handleOnChange}
      styles={selectStyles}
      placeholder="Seleccione Comercio"
      isClearable={false}
    />
  );
}

function CommerceSelector(props: any) {
  const { id, options, value, required, disabled, readonly, onChange } = props;
  return (
    <CustomSelectWidget
      id={id}
      options={options.enumOptions}
      value={value}
      required={required}
      disabled={disabled}
      readonly={readonly}
      onChange={onChange}
    />
  );
}

export default CommerceSelector;
