import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useContext, useEffect } from "react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import AppNavbar from "./AppNavbar";
import Sidebar from "./Sidebar";
import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";
import { getApi } from "@beeldit/core/helpers/api";
import { t } from "i18next";
import useGetCommerceAction from "@main/commerces/actions/get-config/useGetCommerceAction";

function Panel() {
  const { tokenLoaded, user } = useContext(AuthContext);

  /** TODO - Encapsular en una logica aparte que además compruebe que estos parametros son numericos */
  const { setTenantId } = useContext(TenantAndCommerceContext);
  const { setCommerceId } = useContext(TenantAndCommerceContext);
  const { getCommerceFunction } = useGetCommerceAction();

  let { tenantId, commerceId } = useParams();

  if (!tenantId) {
    tenantId = "1";
  }

  setTenantId(parseInt(tenantId));
  getApi().defaults.headers.common["tenantId"] = tenantId;

  if (!commerceId) {
    commerceId = "1";
  }

  setCommerceId(parseInt(commerceId));
  getApi().defaults.headers.common["commerceId"] = commerceId;

  useEffect(() => {
    if (!commerceId || !tokenLoaded) return;
    getCommerceFunction(parseInt(commerceId));
  }, [commerceId, tokenLoaded]);

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  const items: any[] = [
    {
      label: t("customers"),
      to: `/panel/${tenantId}/${commerceId}/customers`,
      icon: icon({ name: "users", style: "solid" }),
    },
    {
      label: t("invoices"),
      to: `/panel/${tenantId}/${commerceId}/invoices`,
      icon: icon({ name: "file-invoice", style: "solid" }),
    },
    {
      label: t("movements"),
      to: `/panel/${tenantId}/${commerceId}/movements`,
      icon: icon({ name: "exchange-alt", style: "solid" }),
    },
    {
      label: t('box_days'),
      to: `/panel/${tenantId}/${commerceId}/box-days`,
      icon: icon({ name: "calculator", style: "solid" }),
    }
  ];

  return (
    <div>
      <AppNavbar items={items} />
      <div className="container-fluid">
        <div className="row">
          <Sidebar items={items} />
          <div className="page-content p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
