import BeelditTypeSelector from "@beeldit/core/components/BeelditTypeSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",
    // movement_id: {
    //     "ui:widget": "movement-selector",
    //     "ui:options": {
    //         label: false, // Oculta el título del campo
    //     }
    // },
    // report_id: {
    //     "ui:widget": "report-selector",
    //     "ui:options": {
    //         label: false // Oculta el título del campo
    //     }
    // },
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
    start_date: {
      "ui:options": {
        label: true, // Oculta el título del campo
        placeholder: "Desde",
        title: "Desde",
      },
    },
    end_date: {
      "ui:options": {
        label: true, // Oculta el título del campo
        placeholder: "Hasta",
        title: "Hasta",
      },
    },
    type: {
      "ui:options": {
        label: true, // Oculta el título del campo
        placeholder: "Tipo",
        title: "Tipo",
        configPath: "types.movements.type",
      },
      "ui:widget": "type-select",         
    },
    origin_type: {
      "ui:options": {
        label: true, // Oculta el título del campo
        placeholder: "Origen",
        title: "Origen",
        configPath: "types.movements.origin_type",
      },
      "ui:widget": "type-select",         
    },
  },
  widgets: {
    "type-select": BeelditTypeSelector    
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
