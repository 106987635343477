import React, { createContext, useState, useContext } from "react";
import { TableData } from "@beeldit/core/models/TableData.interface";
import { useLocation } from "react-router-dom";
import { initFilters } from "@beeldit/core/helpers/useFilters";

interface ReportActionContextType {
  tableFilters: any;
  setTableFilters: (tableFilters: any) => void;
  tableData: TableData;
  setTableData: (tableData: TableData) => void;
  orderBy: string | null;
  setOrderBy: (orderBy: string | null) => void;    
}

export const ReportActionContext = createContext<ReportActionContextType>({
  tableFilters: {},
  setTableFilters: () => {},
  tableData: { elements: [] },
  setTableData: () => {},
  orderBy: null,
  setOrderBy: () => {},
});

interface ReportActionProviderProps {
  children: React.ReactNode;
}

export const ReportActionProvider: React.FC<ReportActionProviderProps> = ({
  children,
}) => {
  const startDate = new Date().setDate(1);
  const location = useLocation();
  const possibleURLFilters = {start_date: null, end_date: null};
  const initializedFilters: any = initFilters(possibleURLFilters, location);
  const defaultFilters = { start_date: new Date(startDate).toISOString().slice(0, 10), end_date: new Date().toISOString().slice(0, 10), ...initializedFilters };
  const mergedFilters = { ...defaultFilters, ...initializedFilters };
  const [tableFilters, setTableFilters] = useState(mergedFilters);
  const [tableData, setTableData] = useState<TableData>({ elements: [] });
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const value = {
    tableFilters,
    setTableFilters,
    tableData,
    setTableData,
    orderBy,
    setOrderBy,
  };
  return (
    <ReportActionContext.Provider value={value}>
      {children}
    </ReportActionContext.Provider>
  );
};
