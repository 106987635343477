import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { ExportActionContext } from "./ExportActionContext";
import toExport from "@beeldit/core/services/export";
import { ListActionContext } from "../list/ListActionContext";

function useExport() {
  const { tableFilters: exportFilters, orderBy } = useContext(ListActionContext);
  const errorHandler = useErrorHandler();
  const extension = 'csv';
  return () => {
    let filterPath = `extension=${extension}`;
    if (exportFilters) {
      for (const [key, value] of Object.entries(exportFilters)) {
        if (value) {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (orderBy) {
      filterPath += `&orderBy=${orderBy}`;
    }
    toExport("movements", filterPath)
      .then((response: any) => {
        // Tratar la respuesta como Blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Movimientos.${extension}`); // Aquí puedes especificar el nombre del archivo si lo tienes
        document.body.appendChild(link);
        link.click();

        // Limpieza: elimina el link y el URL del objeto
        if (link.parentNode) {
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useExport;
