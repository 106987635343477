import { ListActionProvider } from "../actions/list/ListActionContext";
import { RemoveActionProvider } from "../actions/remove/RemoveActionContext";
import { CreateActionProvider } from "../actions/create/CreateActionContext";
import { EditActionProvider } from "../actions/edit/EditActionContext";
import { ImportActionProvider } from "../actions/import/ImportActionContext";
import { ExportActionProvider } from "../actions/export/ExportActionContext";
import BoxDaysPage from "../pages/BoxDaysPage";
import { ReportActionProvider } from "../actions/report/ReportActionContext";

export default function BoxDayRouterWrapper(props: {
  config?: any;
  filters?: any;
  key?: string;
  formDefaultValues?: any;
  formFilterValues?: any;
}) {
  const { config, filters, key, formDefaultValues, formFilterValues } = props;

  return (
    <ListActionProvider key={key}>
      <ReportActionProvider>
        <RemoveActionProvider>
          <CreateActionProvider>
            <EditActionProvider>
              <ImportActionProvider>
                <ExportActionProvider>
                  <BoxDaysPage
                    formDefaultValues={formDefaultValues}
                    config={config}
                    filters={filters}
                    formFilterValues={formFilterValues}
                  />
                </ExportActionProvider>
              </ImportActionProvider>
            </EditActionProvider>
          </CreateActionProvider>
        </RemoveActionProvider>
      </ReportActionProvider>
    </ListActionProvider>
  );
}
