import { useContext, useEffect, useState } from "react";
import { ReportActionContext } from "../actions/report/ReportActionContext";
import { Table } from "react-bootstrap";
import { t } from "i18next";
import {TenantAndCommerceContext} from "@main/contexts/TenantAndCommerceContext";

function BoxDayReport() {
  //Obtenemos tableData de ReportActionContext
  const { tableData } = useContext(ReportActionContext);
  const { commerce } = useContext(TenantAndCommerceContext);


  const [groupedData, setGroupedData] = useState<any>();

  const [boxTypes, setBoxTypes] = useState([] as any);

  // Cada vez que cambie tableData tenemos que ordenar los datos por fecha
  useEffect(() => {
    sortData();
  }, [tableData]);

  const sortData = () => {
    // Recorremos los datos de tableData y si en boxTypes no existe el box_type_id lo agregamos
    tableData.elements.forEach((element: any) => {
      if (
        !boxTypes.find((boxType: any) => boxType.id === element.box_type_id)
      ) {
        boxTypes.push({
          id: element.box_type_id,
          name: element.box_type.name,
          fields: element.box_type.fields,
        });
      }
    });
    // Ordenamos los datos por fecha de mayor a menor
    tableData.elements.sort((a: any, b: any) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
    // Agrupamos los datos por fecha
    const groupedData = tableData.elements.reduce((acc: any, current: any) => {
      const date = current.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(current);
      return acc;
    }, {});
    setGroupedData(groupedData);
  };

  // Devolver la fecha como dd/mm
  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.getDate()}/${dateObj.getMonth() + 1}`;
  };

  const formatAmount = (value: any) => {
    // Si es numérico
    if (!isNaN(parseFloat(value)) && !isNaN(value - 0)) {
      return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: commerce ? commerce.currency : 'EUR',
      }).format(value);
    }

  }

  // Vamos a crear una tabla cuya cabecera cerá fecha y luego por cada box_type tantos td como fields tengan pero un colspan=fields.length
  return (
    <>
      {groupedData && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>Fecha</th>
              {boxTypes.map((boxType: any) => (
                <th
                  colSpan={boxType.fields.length + 2}
                  key={boxType.id}
                  className="text-center"
                >
                  {boxType.name}
                </th>
              ))}
              <th></th>
            </tr>
            <tr>
              <th></th>
              {boxTypes.map((boxType: any) => (
                <>
                  <th>S. ini</th>
                  {boxType.fields.map((field: any) => (
                    <th key={field.name}>{t(field.name)}</th>
                  ))}
                  <th>S. fin</th>
                </>
              ))}
              <th>Total efec</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData).map((date: any) => {
              let total = 0;
              return (
              <tr key={date}>
                <td>{formatDate(date)}</td>
                {boxTypes.map((boxType: any) => {
                  const boxDay = groupedData[date].find(
                    (element: any) => element.box_type_id === boxType.id
                  );
                  if (boxDay) {
                    total += boxDay.final_balance;
                  }
                  return (
                    <>
                      <td key={boxType.id}>
                        {boxDay ? formatAmount(boxDay.initial_balance) : ""}
                      </td>
                      {boxType.fields.map((field: any) => (
                        <td key={field.name}>
                          {boxDay ? formatAmount(boxDay.movements[field.name].value) : ""}
                        </td>
                      ))}
                      <td key={boxType.id} className="final-balance">
                        {boxDay ? formatAmount(boxDay.final_balance) : ""}
                      </td>
                    </>
                  );
                })}
                <td>{formatAmount(total)}</td>
              </tr>);
            })}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default BoxDayReport;
