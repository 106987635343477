import React, { useContext, useEffect, useState } from "react";

import { ListActionProvider } from "../actions/list/ListActionContext";
import { RemoveActionProvider } from "../actions/remove/RemoveActionContext";
import { CreateActionProvider } from "../actions/create/CreateActionContext";
import { EditActionProvider } from "../actions/edit/EditActionContext";
import MovementsPage from "../pages/MovementsPage";
import { ImportActionProvider } from "../actions/import/ImportActionContext";
import { ExportActionProvider } from "../actions/export/ExportActionContext";

export default function MovementRouterWrapper(props: {
  config?: any;
  filters?: any;
  key?: string;
  formDefaultValues?: any;
  formFilterValues?: any;
}) {
  const { config, filters, key, formDefaultValues, formFilterValues } = props;

  return (
    <ListActionProvider key={key}>
      <RemoveActionProvider>
        <CreateActionProvider>
          <EditActionProvider>
            <ImportActionProvider>
              <ExportActionProvider>
                <MovementsPage
                  formDefaultValues={formDefaultValues}
                  config={config}
                  filters={filters}
                  formFilterValues={formFilterValues}
                />
              </ExportActionProvider>
            </ImportActionProvider>
          </EditActionProvider>
        </CreateActionProvider>
      </RemoveActionProvider>
    </ListActionProvider>
  );
}
