import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import BoxTypeSelector from "../../box-types/components/BoxTypeSelector";
import { stringify } from "querystring";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function BoxDayForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const [movementsStructure, setMovementsStructure] = useState<any>({});

  const translations = {
    date: t("date"),
    initial_balance: t("initial_balance"),
    final_balance: t("final_balance"),
    box_type_id: t("box_type_id"),
  };

  let schema: any = {
    type: "object",
    // required: ['minutes', 'box_day_id', 'task', 'date', 'report_id'],
    properties: {
      date: {
        type: "string",
        title: translations.date,
        format: "date",
        readOnly: true,
      },
      initial_balance: {
        type: "number",
        title: translations.initial_balance,
        readOnly: true,
      },
      final_balance: {
        type: "number",
        title: translations.final_balance,
        readOnly: true,
      },
      box_type_id: {
        type: "number",
        title: translations.box_type_id,
        readOnly: true,
      },
      movements: {},
    },
  };
  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    box_type_id: {
      "ui:widget": "box-type-selector",
    },
  };

  const customWidgets = {
    ...widgets,
    ...{
      "box-type-selector": BoxTypeSelector,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  useEffect(() => {
    if (!schema.properties.movements.properties) {
      let movementsSchema: any = {
        type: "object",
        title: "",
        properties: {},
      };
      Object.keys(element.movements).forEach((key: string) => {
        movementsSchema.properties[key] = {
          type: "object",
          title: "",
          properties: {
            value: {
              type: "number",
              title: key,
              readOnly: element.movements[key].type == "manual" ? false : true,
            },
          },
        };
      });
      schema.properties.movements = movementsSchema;
      setFinalSchema(schema);
    }
  }, []);
  useEffect(() => {
    let finalBalance = element.initial_balance;
    // finalBalance debe ser initial_balance + sumatoria de los valores de los movimientos
    Object.keys(element.movements).forEach((key: string) => {
      finalBalance += element.movements[key].value;
    });
    element.final_balance = finalBalance;
    setElement({...element})
  }, [element.movements]);

  return (
    <>
      <JSONSchemaForm
        noHtml5Validate
        showErrorList={false}
        ref={formRef}
        schema={finalSchema}
        uiSchema={uiSchema}
        widgets={customWidgets}
        formData={element}
        validator={validator}
        onChange={handleChange}
        onSubmit={onSubmit}
        onError={log("errors")}
        extraErrors={backendFormErrors}
      />
    </>
  );
}

export default BoxDayForm;
