import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { Download, Pencil, Plus, Trash } from "@phosphor-icons/react";
import { UploadSimple } from "@phosphor-icons/react/dist/ssr";

const tableConfig: FinalTableConfig = {
  index: null,
  actions: [],
  rowActions: [
    {
      name: RowAction.EDIT,
      label: "edit",
      icon: <Pencil size={20} />,
      allowed: true,
      show: true,
      action: (element: any) => {
        console.log("edit", element);
      },
    }
    
  ],
  rowConfiguration: {
    class: (element: any) => {
      return null;
    },
  },
  columns: [
    {
      name: "id",
      label: "#",
      key: "id",
      type: "number",
      show: true,
      allowOrder: true,
    },
    {
      name: "date",
      label: "date",
      key: "date",
      type: "date",
      show: true,
      allowOrder: true,
    },
    {
      name: "box_type_id",
      label: "box_type_id",
      key: "box_type.name",            
      show: true,
      allowOrder: true,
    },
    {
      name: "initial_balance",
      label: "initial_balance",
      key: "initial_balance",
      type: "currency",
      currency: "EUR",
      show: true,
      allowOrder: true,
    },
    {
      name: "final_balance",
      label: "final_balance",
      key: "final_balance",
      type: "currency",
      currency: "EUR",
      show: true,
      allowOrder: true,
    }
  ],
  pagination: {
    enabled: true,
    back: true,
  },
};
const getConfig: any = (functions: any) => {
  const {
    getFunction,
    indexFunction,
    initializeFunction,
    prepareRemoveFunction,
    importFunction,
    exportFunction,
    commerce
  } = functions;
  tableConfig.index = () => indexFunction();
  tableConfig.rowActions[0].action = (id: any) => getFunction(id);
  tableConfig.columns[3].currency = commerce ? commerce.currency : 'EUR';
  tableConfig.columns[4].currency = commerce ? commerce.currency : 'EUR';
  return tableConfig;
};
export default getConfig;
