import BeelditTypeSelector from "@beeldit/core/components/BeelditTypeSelector";
import { BoxType } from "../../box-types/models/BoxType";
import BoxTypeSelector from "../../box-types/components/BoxTypeSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",
    // box_day_id: {
    //     "ui:widget": "box_day-selector",
    //     "ui:options": {
    //         label: false, // Oculta el título del campo
    //     }
    // },
    // report_id: {
    //     "ui:widget": "report-selector",
    //     "ui:options": {
    //         label: false // Oculta el título del campo
    //     }
    // },
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
    start_date: {
      "ui:options": {
        label: true, // Oculta el título del campo
        placeholder: "Desde",
        title: "Desde",
      },
    },
    end_date: {
      "ui:options": {
        label: true, // Oculta el título del campo
        placeholder: "Hasta",
        title: "Hasta",
      },
    },
    box_type_id: {
      "ui:options": {
        label: true, // Oculta el título del campo
        placeholder: "Tipo",
        title: "Tipo",        
      },
      "ui:widget": "box-type-select",
    },    
  },
  widgets: {
    "box-type-select": BoxTypeSelector,
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
