import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { ReportActionContext } from "./ReportActionContext";
import index from "@beeldit/core/services";

function useIndex() {
  const { tableFilters, orderBy, setTableData } =
    useContext(ReportActionContext);
  const errorHandler = useErrorHandler();
  return () => {
    let filterPath = "relations=box_type";
    if (tableFilters) {
      for (const [key, value] of Object.entries(tableFilters)) {
        if (value) {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (orderBy) {
      filterPath += `&orderBy=${orderBy}`;
    }
    index("box_days", filterPath)
      .then((response: any) => {
        setTableData(response.data);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useIndex;
